import "./App.css";

function App() {
  return (
    <div>coming soon!</div>

  );
}

export default App;

/*
Next:
- footer
- once i start working on more content maybe do a branch


*/
